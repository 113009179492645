<template>
  <SliderCarrousel
    :slides="slides"
    :totalSteps="1"
    :callback="navigateHome"
  >
    <template v-slot:content>
      <Button
        v-if="slides"
        class="margin-y"
        :text="$translations.aftercall.slider[1].buttons[0].text"
        type='block'
        @clicked="goMatching"
      />
    </template>
  </SliderCarrousel>
</template>

<script>
import { PNG, Button } from '@seliaco/red-panda'
import { mapGetters } from 'vuex'
import { WCactive } from './configuration/configuration'
import SliderCarrousel from '@/components/misc/SliderCarrousel.vue'
import { WarrantyEvent } from '@/types/events'
import NavigateMatchingResetMixin from '@/mixins/navigate-matching-reset-mixin'
export default {
  name: 'WCactive',
  components: {
    SliderCarrousel,
    Button
  },
  mixins: [NavigateMatchingResetMixin],
  data () {
    return {
      loading: true,
      slides: null
    }
  },
  beforeMount () {
    this.slides = WCactive(this.$translations, PNG)
    this.loading = false
    WarrantyEvent.Warranty_Call_Active_Viewed()
  },
  methods: {
    goMatching () {
      WarrantyEvent.Warranty_Call_Active_Clicked('Agendar orientacion')
      this.handleMatchingNavigation()
    },
    navigateHome () {
      WarrantyEvent.Warranty_Call_Active_Clicked('En otro momento')
      this.$router.push({
        name: 'Home'
      })
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  }
}
</script>
